import React from 'react'
import styled from 'styled-components/macro'

const Input = ({ className, error, ...props }: any) => (
  <Container className={className}>
    <StyledInput {...props} />
    <Underline error={error}></Underline>
  </Container>
)

const StyledInput = styled.input`
  padding: 5px;
  font-size: 14px;
  width: 100%;
  border: none;
  /* border radius shows up when brosers autofill inputs */
  border-radius: 4px;
  outline: transparent;
  background-color: transparent;
`
const Container = styled.div`
  position: relative;
  display: inline-block;
  min-width: 27px;
`
interface Props {
  error: boolean
}
const Underline = styled.div`
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: ${({ error }: Props) => error ? 'red' : '#4e6783'};
  bottom: 0px;
  left: 0px;
  ::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: ${({ error }: Props) => error ? 'red' : '#4e6783'};
    bottom: 0px;
    left: 0px;
    transform: scaleX(0);
    transition: transform 100ms ease-in-out;
  }
  ${StyledInput}:focus + &::after {
    transform: scaleX(1)
  }
`

export default Input