import React from 'react'
import styled from 'styled-components/macro'
import posed, { PoseGroup } from 'react-pose'
import { Keys } from '../../client-utils'

interface Props {
  open: boolean
  onRequestClose: () => void
  className?: string
}
interface State {}
export default class Modal extends React.Component<Props, State> {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown)
  }

  handleKeydown = (event: KeyboardEvent) => {
    if (event.keyCode === Keys.Escape) {
      this.props.onRequestClose()
    }
  }

  handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // stop the click event here so we don't close the modal when click is inside it
    event.stopPropagation()
  }

  render() {
    return (
      <PoseGroup>
        {this.props.open &&
          <Overlay key="overlay" onClick={this.props.onRequestClose}>
            <Content key="content" onClick={this.handleContentClick}>
              {this.props.children}
            </Content>
          </Overlay>
        }
      </PoseGroup>
    )
  }
}

const ContentDiv = posed.div({
  enter: { y: '-50%', opacity: 1 },
  exit: { y: '-40%', opacity: 0 },
})
const Content = styled(ContentDiv)`
  position: fixed;
  top: 50%;
  width: fit-content;
  margin: auto;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  z-index: 9999;
`
const OverlayDiv = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 150 }
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 }
  },
})
const Overlay = styled(OverlayDiv)`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
`