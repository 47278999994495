import * as React from 'react'
import posed, { PoseGroup } from 'react-pose'
import styled from 'styled-components/macro'

interface Item {
  key: string | number,
  text: string,
  onSelect: () => void,
}

interface Props {
  items: Item[],
  open: boolean,
  onRequestClose: () => void
  color?: string,
  className?: string,
}
interface State {}

class Menu extends React.Component<Props, State> {
  menuRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    document.body.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClick)
  }

  handleClick = (event: MouseEvent) => {
    const menuEl = this.menuRef.current
    if (menuEl) {
      const bb = menuEl.getBoundingClientRect()
      const x = event.clientX
      const y = event.clientY
      if (x < bb.left || x > bb.left + bb.width || y < bb.top || y > bb.top + bb.height) {
        this.props.onRequestClose()
      }
    }
  }

  handleItemSelected = (item: Item) => {
    this.props.onRequestClose()
    item.onSelect()
  }

  render = () => {
    return (
      <div css='position: relative;'>
        <PoseGroup>
          {this.props.open &&
            <MenuContainer key="menu" ref={this.menuRef}>
              {this.props.items.map(item => (
                <MenuItem color={this.props.color} key={item.key} onClick={() => this.handleItemSelected(item)}>
                  {item.text}
                </MenuItem>
              ))}
            </MenuContainer>
          }
        </PoseGroup>
      </div>
    )
  }
}

export default Menu

const borderRadius = '5px'
const MenuContainerDiv = posed.div({
  enter: {
    staggerChildren: 50,
    delayChildren: 100,
    scale: 1,
  },
  exit: {
    staggerChildren: 50,
    staggerDirection: -1,
    scale: 0,
    delay: 100,
  }
})
const MenuItemDiv = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: -20, opacity: 0 }
})
const MenuItem = styled(MenuItemDiv)`
  padding: 8px;
  user-select: none;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid lightslategray;
  border-bottom: none;
  cursor: pointer;
  &:hover {
    background-color: ${(props: { color?: string }) => props.color || '#6aa5ff40'}
  }
  &:last-child {
    border-bottom: 1px solid lightslategray;
    border-bottom-right-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
  }
  &:first-child {
    border-top-right-radius: ${borderRadius};
    border-top-left-radius: ${borderRadius};
  }
`
const MenuContainer = styled(MenuContainerDiv)`
  background-color: white;
  border-radius: ${borderRadius};
  position: absolute;
  width: 135px;
  z-index: 9999;
`