import * as React from 'react'
import swapController from '../../controllers/SwapController'
import { HttpStatus } from '../../shared/utils'
import { addToShift, dropFromShift } from '../../apiRequest'
import { snackbar } from '../../controllers/SnackbarController'
import SimpleDate from '../../shared/SimpleDate'
import { Button, Modal, Spinner } from '../ui-kit'
import { BoxShadow } from '../../CommonStyles'
import { PersonI, ShiftI } from '../../shared/entity-interfaces'
import styled from 'styled-components/macro'
import { emit, AppEvent } from '../../AppEvents'
import { AppContextComponent, AppContextI } from '../../client-utils';

interface Props {
  open: boolean
  shift: ShiftI
  onStartSwap: (shift: ShiftI) => void
  onNameClicked: (shift: ShiftI, person: PersonI) => void
  onRequestClose: () => void
}

interface State {}

export default class ShiftModal extends AppContextComponent<Props, State> {
  handleAdd = async () => {
    const res = await addToShift(this.props.shift.id, this.context.user.id)
    if (res.status === HttpStatus.Created) {
      this.props.onRequestClose()
      snackbar("Add successful", "Undo", this.handleDrop)
      emit(AppEvent.ScheduleUpdated)
    } else {
      snackbar("An error occurred", "Retry", this.handleAdd)
    }
  }

  handleDrop = async () => {
    const res = await dropFromShift(this.props.shift.id, this.context.user.id)
    if (res.status === HttpStatus.Ok) {
      this.props.onRequestClose()
      snackbar("Drop successful", "Undo", this.handleAdd)
      emit(AppEvent.ScheduleUpdated)
    } else {
      snackbar("An error occurred", "Retry", this.handleDrop)
    }
  }
  
  handleStartSwap = () => {
    this.props.onRequestClose()
    this.props.onStartSwap(this.props.shift)
  }

  handleNameClicked = async (person: PersonI) => {
    this.props.onNameClicked(this.props.shift, person)
  }

  render() {
    const { shift } = this.props
    if (!shift) return <Modal open={false} onRequestClose={this.props.onRequestClose} />

    const userId = this.context.user.id
    const isSwapping = swapController.isSwapping
    const includesCurrentUser = shift.personShifts.find(ps => ps.person.id === userId) !== undefined
    const shiftStart = SimpleDate.fromSerialized(shift.startTime)
    const shiftEnd = SimpleDate.fromSerialized(shift.endTime)
    let hasFoundShiftLead = false

    return (
      <Modal
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
      >
        <Title>{shift.team.name}</Title>
        <Title>{shift.shiftType.name}</Title>
        <Heading>{shiftStart.getFormattedDateString()}</Heading>
        <Heading>{shiftStart.getFormattedTimeString()} to {shiftEnd.getFormattedTimeString()}</Heading>
        <FlexColumn>
          {this.props.shift.personShifts.map(ps => {
            const person = ps.person
            const hasShiftLeadAttribute = !!person.personAttributes.find(pa => pa.attribute.description === 'Shift Lead')
            let isShiftLead = false
            if (!hasFoundShiftLead && hasShiftLeadAttribute) {
              isShiftLead = true
              hasFoundShiftLead = true
            }
            return <PersonName isShiftLead={isShiftLead} key={person.id} swapping={swapController.isSwapping} onClick={() => this.handleNameClicked(person)}>{`${person.firstName} ${person.lastName}`}</PersonName>
          })}
        </FlexColumn>
        <ButtonsContainer>
          {!isSwapping && !includesCurrentUser && <Button onClick={this.handleAdd}>Add Me</Button>}
          {!isSwapping && includesCurrentUser && <Button onClick={this.handleDrop}>Drop Me</Button>}
          {!isSwapping && includesCurrentUser && <Button onClick={this.handleStartSwap}>Swap</Button>}
        </ButtonsContainer>
      </Modal>
    )
  }
}

const Title = styled.h1`
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 11px;
  min-width: 192px;
`
const Heading = styled.h2`
  margin: 10px 0px;
  font-size: 16px;
  font-weight: normal
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`
interface PersonNameProps {
  swapping: boolean
  isShiftLead: boolean
}
const PersonName = styled.div`
  margin: 3px;
  padding: 5px;
  font-weight: ${(props: PersonNameProps) => props.isShiftLead ? 'bold' : 'normal'};
  border-radius: 4px;
  cursor: ${(props: PersonNameProps) => props.swapping ? 'pointer' : 'auto'};
  :hover {
    background-color: ${(props: PersonNameProps) => props.swapping ? '#dadada' : 'transparent'}
  }
`