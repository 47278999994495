import React from 'react'
import styled from 'styled-components/macro'
import { Colors } from '../../CommonStyles'
import NameButton from './NameButton'
import { AppContextComponent } from '../../client-utils'
import { getNextShift } from '../../apiRequest';
import SimpleDate from '../../shared/SimpleDate';
import { onAppEvent, AppEvent } from '../../AppEvents';

interface Props {}
interface State {
  loading: boolean
  topNextShiftString: string
  bottomNextShiftString: string
}

export default class Header extends AppContextComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      topNextShiftString: '',
      bottomNextShiftString: ''
    }
  }

  updateNextShift = async () => {
    const now = SimpleDate.now(this.context.user.memberships[0].team.utcOffset)
    const nextShift = await getNextShift(this.context.user.id, now)

    let hasUpcomingShift = nextShift !== null
    const tomorrow = SimpleDate.now(this.context.user.memberships[0].team.utcOffset).startOfDay().addDays(1)
    const dayAfterTomorrow = tomorrow.addDays(1)

    let topNextShiftString = 'You have no upcoming shifts'
    let bottomNextShiftString = ''
    if (hasUpcomingShift) {
      topNextShiftString = 'Your next shift is'
      const nextShiftStart = SimpleDate.fromSerialized(nextShift.startTime)
      if (nextShiftStart.isBefore(tomorrow)) {
        bottomNextShiftString = `today at ${nextShiftStart.getFormattedTimeString()}`
      } else if (nextShiftStart.isBefore(dayAfterTomorrow)) {
        bottomNextShiftString = `tomorrow at ${nextShiftStart.getFormattedTimeString()}`
      } else {
        bottomNextShiftString = `${nextShiftStart.weekdayString} at ${nextShiftStart.getFormattedTimeString()}`
      }
    }
    this.setState({ loading: false, topNextShiftString, bottomNextShiftString })
  }

  componentDidMount = () => {
    this.updateNextShift()
    onAppEvent(AppEvent.ScheduleUpdated, this.updateNextShift)
  }

  render() {
    return (
      <Container>
        {!this.state.loading &&
          <React.Fragment>
            <NextShift>{this.state.topNextShiftString}</NextShift>
            {this.state.bottomNextShiftString.length > 0 &&
              <BottomNextShift>{this.state.bottomNextShiftString}</BottomNextShift>
            }
            <NameButton />
          </React.Fragment>
        }
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: ${Colors.MainBlue};
  height: 90px;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const BottomNextShift = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
`
const NextShift = styled.span`
  font-size: 18px;
`