import * as React from 'react'
import Calendar from '../Calendar/Calendar'
import { AppContextComponent } from '../../client-utils'
import { logout } from '../../apiRequest'
import SimpleDate from '../../shared/SimpleDate'
import { Settings, EventAvailable, } from 'styled-icons/material'
import { emit, AppEvent } from '../../AppEvents'
import styled from 'styled-components/macro'
import { Button } from '../ui-kit'
import Header from './Header'

interface Props {}

interface State {
  view: View
  calendarLoaded: boolean
  isSwapping: boolean
  dayOffset: number
}

enum View {
  Individual,
  Full
}

export default class HomePage extends AppContextComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      view: View.Individual,
      calendarLoaded: false,
      isSwapping: false,
      dayOffset: 0,
    }
  }

  changeCalendar = () => {
    if (this.state.view === View.Individual) {
      this.setState({ view: View.Full })
    } else {
      this.setState({ view: View.Individual })
    }
  }

  handleLogout = async () => {
    await logout()
    window.location.reload()
  }

  handleCalendarLoaded = () => {
    this.setState({ calendarLoaded: true })
  }

  render() {
    return (
      <div>
        <Header />
        <Calendar
          startDate={SimpleDate.now(this.context.user.memberships[0].team.utcOffset).startOfDay().addDays(this.state.dayOffset)}
          onLoad={this.handleCalendarLoaded}
        />
        {this.state.calendarLoaded && <ButtonsContainer>
         <PageButton onClick={() => emit(AppEvent.GoToAvailabilityPage)}>
            <EventAvailable size={30} />
            <ButtonLabel>Update availability</ButtonLabel>
          </PageButton>
          {/* Display Admin Page button if the user is an admin of any of their teams */}
          {this.context.user.memberships.find(m => m.isAdmin) !== undefined &&
            <PageButton onClick={() => emit(AppEvent.GoToAdminPage)}>
              <Settings size={30} />
              <ButtonLabel>Admin Page</ButtonLabel>
            </PageButton>
          }
        </ButtonsContainer>}
      </div>
    )
  }
}

const CalendarNavButton = styled(Button)`
  background-color: #fff;
  font-size: 14px;
  color: #000;
  border: 1px solid lightgray;
  margin-right: 10px;
  :hover {
    background-color: #efefef;
  }
  :active {
    background-color: #d8d8d8;
  }
`
const PageButton = styled(CalendarNavButton)`
  width: 195px;
  text-align: left;
  margin-bottom: 10px;
`
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
`
const ButtonLabel = styled.span`
  position: relative;
  margin-left: 10%;
`