import * as React from 'react'
import { Button } from './ui-kit'
import { Close } from 'styled-icons/material'
import styled, { css } from 'styled-components/macro'
import posed, { PoseGroup } from 'react-pose'

interface MessageInfo {
  message: string
  actionText?: string
  actionHandler?: Function
}

interface Props {}
interface State {
  open: boolean
  messageInfo: MessageInfo
}

export default class Snackbar extends React.Component<Props, State> {
  timeout: NodeJS.Timeout | null = null

  constructor(props: Props) {
    super(props)
    this.state = {
      open: false,
      messageInfo: {
        message: ''
      },
    }
  }

  cancelTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = null
  }

  show = (messageInfo: MessageInfo) => {
    this.setState({ open: true, messageInfo })
    this.timeout = setTimeout(() => this.setState({ open: false }), 5000)
  }

  handleActionClicked = () => {
    if (this.state.messageInfo.actionHandler) {
      this.state.messageInfo.actionHandler()
    }

    this.setState({ open: false })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { messageInfo, open } = this.state

    const actions: React.ReactNodeArray = []
    if (messageInfo.actionText) {
      actions.push(
        <ActionButton key="action" onClick={this.handleActionClicked}>
          {messageInfo.actionText}
        </ActionButton>
      )
    }

    actions.push(
      <CloseButton
        key="close"
        aria-label="Close"
        onClick={this.handleClose}
      >
        <Close size={20} />
      </CloseButton>
    )

    return (
      <PoseGroup flipMove={false}>
        {open &&
            <StyledSnackbar key="snackbar">
              <Text>{messageInfo.message}</Text>
              <Actions>
                { actions }
              </Actions>
            </StyledSnackbar>
        }
      </PoseGroup>
    )
  }
}

const snackbarButtonStyles = css`
  transition: background-color 100ms ease-in-out;
  border: none;
  color: #fff;
  :hover {
    background-color: hsl(222, 14%, 28%);
  }
  :active {
    background-color: hsl(222, 14%, 32%);
  }
`
const CloseButton = styled(Button)`
  ${snackbarButtonStyles}
  background-color: transparent;
  border-radius: 50%;
  padding: 7px 7px;
`
const ActionButton = styled(Button)`
  ${snackbarButtonStyles}
  background-color: hsl(222, 14%, 24%);
  border-radius: 3px;
`
const Actions = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 2px;
  }
`
const Text = styled.span`
  font-size: 14px;
  color: #fff;
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  max-width: 60vw;
  margin-right: 10px;
  display: inline-block;
`
const SnackbarDiv = posed.div({
  enter: {
    x: '-50%',
    y: 0,
    transition: { duration: 200 },
  },
  exit: {
    x: '-50%',
    y: 100,
    transition: { duration: 500 },
  },
})
const StyledSnackbar = styled(SnackbarDiv)`
  min-width: 300px;
  width: fit-content;
  min-height: 40px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  padding: 5px 5px 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282C34;
  border-radius: 4px 4px 0 0;
  z-index: 99999;
`