export enum AppEvent {
  GoToHomePage,
  GoToAvailabilityPage,
  GoToAdminPage,
  ScheduleUpdated,
  StartSwap,
  EndSwap,
  WindowResize,
  RecheckAuth,
}

type EventHandler = (event?: AppEvent) => void

class EventEmitter {
  listeners: { [events: number]: EventHandler[] } = {}

  onAppEvent(event: AppEvent, handler: EventHandler) {
    if (!(event in this.listeners)) {
      this.listeners[event] = []
    }
    this.listeners[event].push(handler)
  }

  onAppEventOnce(event: AppEvent, handler: EventHandler) {
    this.onAppEvent(event, () => {
      handler(event)
      this.removeAppEventListener(event, handler)
    })
  }

  // TODO: test this
  removeAppEventListener(event: AppEvent, handler: EventHandler) {
    if (this.listeners[event]) {
      this.listeners[event].splice(this.listeners[event].indexOf(handler), 1)
    }
  }

  public emit(event: AppEvent) {
    if (this.listeners[event]) {
      for (const handler of this.listeners[event]) {
        handler(event)
      }
    }
  }
}

const emitter = new EventEmitter()

export const emit = (event: AppEvent) => {
  emitter.emit(event)
}

export const onAppEvent = (event: AppEvent, handler: EventHandler) => {
  emitter.onAppEvent(event, handler)
}

// TODO: test this
export const removeAppEventListener = (event: AppEvent, handler: EventHandler) => {
  emitter.removeAppEventListener(event, handler)
}

export const onAppEventOnce = (event: AppEvent, handler: EventHandler) => {
  emitter.onAppEventOnce(event, handler)
}