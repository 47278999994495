import React from 'react'
import SimpleDate from '../../shared/SimpleDate'
import { Calendar as FullCalendar, EventInput } from '@fullcalendar/core'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css' // timegrid depends on this stylesheet
import '@fullcalendar/timegrid/main.css'
import timeGridPlugin from '@fullcalendar/timegrid'
import { ArrowBack, ArrowDownward, ArrowForward, EventNote } from 'styled-icons/material'
import { Export } from 'styled-icons/boxicons-regular/Export'
import { AppContextComponent, AppContextI } from '../../client-utils'
import { getCalendarEvents, getAvailablePeople, getAuthenticatedUser, getNextShift, getTeam, getShiftTypes } from '../../apiRequest'
import { Colors } from '../../CommonStyles'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { Button, Menu } from '../ui-kit';
import { ShiftI, PersonI } from '../../shared/entity-interfaces';
import ShiftModal from './ShiftModal'
import swapController from '../../controllers/SwapController';
import { onAppEvent, AppEvent } from '../../AppEvents';
import { snackbar } from '../../controllers/SnackbarController';
import * as ics from 'ics';

interface Props {
  startDate: SimpleDate
  onLoad?: () => void
}

interface State {
  view: View
  month: string
  loadingEvents: boolean
  selectedShift: ShiftI
  showModal: boolean,
  menuIsOpen: boolean
}

enum View {
  Individual,
  Full
}

let classNames = {
  event: 'so-event',
  content: 'so-event-content',
  title: 'so-event-title',
  time: 'so-event-time',
  workersCount: 'so-event-workers-count'
}

// TODO: add more colors
let colors = [ Colors.CalendarEventCoral, '#DCA4EF', '#9DF5DC' ]

export default class Calendar extends AppContextComponent<Props, State> {
  calendar: FullCalendar
  calendarRef = React.createRef<HTMLDivElement>()
  teamEvents: { [id: number]: { withUser: EventInput[], withoutUser: EventInput[] } } = {}
  utcOffset: number;

  constructor(props: Props, context: AppContextI) {
    super(props)

    this.utcOffset = context.user.memberships[0].team.utcOffset;

    const start = SimpleDate.now(this.utcOffset).startOfDay()

    this.state = {
      view: View.Individual,
      month: start.monthString,
      loadingEvents: true,
      selectedShift: null,
      showModal: false,
      menuIsOpen: false
    }
  }

  componentDidMount = () => {
    const el = this.calendarRef.current
    const dayCount = window.innerWidth <= 400 ? 4 : 7;
    // once events are fetched, render the calendar
    this.fetchLatestEvents().then(() => {
      this.calendar = new FullCalendar(el, {
        eventSources: this.context.user.memberships.map(membership => ({
          id: `${membership.teamId} withUser`,
          // pass a function instead of the array directly so we can call calendar.refetchEvents later after modifying the array
          events: () => Promise.resolve(this.teamEvents[membership.teamId].withUser),
          color: 'lightgreen'
        })),
        defaultView: 'timeGrid',
        duration: { days: dayCount },
        plugins: [ timeGridPlugin ],
        header: { left: 'title', center: '', right: '' },
        titleFormat: { month: 'long' },
        height: 'auto',
        minTime: '10:00:00', // TODO: get these values from db based on shift table
        maxTime: '20:00:00',
        allDaySlot: false,
        dateIncrement: { days: 1 },
        eventTextColor: '#000',
        eventBorderColor: 'transparent',
        displayEventTime: false,
        slotEventOverlap: false,
        slotDuration: { hours: 1 },
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: true,
          meridiem: false
        },
        columnHeaderHtml: jsDate => {
          const date = SimpleDate.fromUTCDate(jsDate as Date)
          return `<div style="font-weight: normal; margin: 3px 0;">
            <div>${date.weekdayStringShort}</div>
            <div style="font-size: 22px;">${date.dateOfMonth}</div>
          </div>`
        },
        nowIndicator: true,
        lazyFetching: true,
        loading: (loadingEvents) => {
          if (loadingEvents && this.props.onLoad) this.props.onLoad()
          this.setState({ loadingEvents })
        },
        timeZone: 'UTC',
        now: SimpleDate.now(this.utcOffset + SimpleDate.dst(this.utcOffset)).toUTCDate(),
        eventRender: ({ el, event }) => {
          const workersCount = event.extendedProps.shift.personShifts.length
          const content = el.querySelector('.fc-content') as HTMLElement
          const bg = el.querySelector('.fc-bg') as HTMLElement
          const title = content.querySelector('.fc-title') as HTMLElement

          el.classList.add(classNames.event)
          content.classList.add(classNames.content)
          bg.style.display = 'none'
          title.classList.add(classNames.title)

          const workersCountSpan = document.createElement('div')
          const workers = document.createElement('span')
          const time = document.createElement('span')
          const start = SimpleDate.fromUTCDate(event.start)
          const end = SimpleDate.fromUTCDate(event.end)

          workersCountSpan.classList.add(classNames.workersCount)
          workers.innerText = workersCount === 1 ? '1 person' : `${workersCount} people`
          time.classList.add(classNames.time)
          time.innerText = `${start.getShortTimeString()} - ${end.getShortTimeString()}`

          workersCountSpan.appendChild(workers)
          content.appendChild(time)
          content.appendChild(workersCountSpan)
        },
        eventClick: info => {
          this.setState({ showModal: true, selectedShift: info.event.extendedProps.shift })
        }
      })

      onAppEvent(AppEvent.ScheduleUpdated, this.fetchLatestEvents)
      this.calendar.render()
    })
  }

  changeCalendar = () => {
    if (this.state.view === View.Full) {
      for (const eventSource of this.calendar.getEventSources()) {
        if (eventSource.id.endsWith('withoutUser')) {
          eventSource.remove()
        }
      }
      this.setState({ view: View.Individual })
    } else {
      for (const membership of this.context.user.memberships) {
        this.calendar.addEventSource({
          id: `${membership.teamId} withoutUser`,
          // pass a function instead of the array directly so we can call calendar.refetchEvents later after modifying the array
          events: () => Promise.resolve(this.teamEvents[membership.teamId].withoutUser),
          color: colors[membership.teamId - 1 % colors.length]
        })
      }
      this.setState({ view: View.Full })
    }
  }

  next = () => {
    if (this.calendar) this.calendar.next()
  }

  prev = () => {
    if (this.calendar) this.calendar.prev()
  }

  fetchLatestEvents = async () => {
    const start = SimpleDate.now(this.context.user.memberships[0].team.utcOffset).subDays(14).startOfDay()
    const end = start.addDays(30 + 14)
    const teamIds = this.context.user.memberships.map(m => m.teamId)
    const allEvents = await getCalendarEvents(start, end, teamIds)
    this.teamEvents = {}
    for (const id of teamIds) {
      this.teamEvents[id] = { withUser: [], withoutUser: [] }
    }
    for (const event of allEvents) {
      const shift = (event.extendedProps as any).shift as ShiftI
      const includesUser = shift.personShifts.find(ps => ps.personId === this.context.user.id)
      if (includesUser) {
        this.teamEvents[shift.teamId].withUser.push(event)
      } else {
        this.teamEvents[shift.teamId].withoutUser.push(event)
      }
    }
    if (this.calendar) {
      this.calendar.refetchEvents()
    }
  }

  startSwap = (shift: ShiftI) => {
    swapController.shiftIdA = shift.id
    swapController.isSwapping = true
    if (this.state.view == View.Full) {
      this.calendar.getEventSourceById(`${shift.teamId} withoutUser`).remove()
    }
    this.calendar.addEventSource({
      id: 'swapPlaceholders',
      events: this.teamEvents[shift.teamId].withoutUser,
      color: 'lightblue'
    })
  }

  nameClicked = async (shift: ShiftI, person: PersonI) => {
    if (swapController.isSwapping) {
      swapController.shiftIdB = shift.id
      const didSwap = await swapController.executeSwap(this.context.user.id, person.id)
      if (didSwap) {
        this.calendar.getEventSourceById('swapPlaceholders').remove()
        if (this.state.view === View.Full) {
          // we removed the placeholder event source and now need to add back the actual event source
          this.calendar.addEventSource({
            id: `${shift.teamId} withoutUser`,
            // pass a function instead of the array directly so we can call calendar.refetchEvents later after modifying the array
            events: () => Promise.resolve(this.teamEvents[shift.teamId].withoutUser),
            color: colors[shift.teamId - 1 % colors.length]
          })
        }
        this.fetchLatestEvents()
      }
      this.closeModal()
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  downloadSchedule = async () => {
    var scheduleList = [];
    const start = SimpleDate.now(this.context.user.memberships[0].team.utcOffset).lastSundayOrToday().startOfDay()
    const end = start.addDays(30 + 14)
    const teamIds = this.context.user.memberships.map(m => m.teamId)
    const allEvents = await getCalendarEvents(start, end, teamIds)
    for (const event of allEvents) {
      var currSchedule = [];
      const shift = (event.extendedProps as any).shift as ShiftI
      console.log(shift.startTime.isoString);
      if (shift.personShifts.length > 0) {
        console.log(shift.personShifts.map(p => p.person.firstName + " " + p.person.lastName).reduce((x, y) => x + "; " + y));
        currSchedule = shift.personShifts.map(p => p.person.firstName + " " + p.person.lastName);
      } else {
        currSchedule.push("N/A");
        console.log("no one scheduled");
      }
      currSchedule.unshift(shift.startTime.isoString);
      currSchedule.unshift(shift.team.name);
      scheduleList.push(currSchedule);
    }
    console.log(scheduleList);
    this.downloadShifts(scheduleList);
  }

  downloadPeopleAvailable = async () => {
    var availList = [];
    const start = SimpleDate.now(this.context.user.memberships[0].team.utcOffset).lastSundayOrToday().startOfDay()
    const end = start.addDays(30 + 14)
    const teamIds = this.context.user.memberships.map(m => m.teamId)
    const allEvents = await getCalendarEvents(start, end, teamIds) 
    for (const event of allEvents) {
      var currAvail = [];
      const shift = (event.extendedProps as any).shift as ShiftI
      const people = await getAvailablePeople(shift.teamId, shift.startTime.isoString, shift.endTime.isoString);
      console.log(shift.startTime.isoString);
      if (people.length > 0) {
        console.log(people.map(p => p.firstName + " " + p.lastName).reduce((x, y) => x + "; " + y));
        currAvail = people.map(p => p.firstName + " " + p.lastName);
      } else {
        currAvail.push("N/A");
        console.log("no one available");
      }
      currAvail.unshift(shift.startTime.isoString);
      currAvail.unshift(shift.team.name);
      availList.push(currAvail);
    }
    console.log(availList);
    this.downloadShifts(availList);
  }

  downloadShifts = (scheduleList) => {
    const rows = ["team", "time", "members"];
    scheduleList.unshift(rows);
    let csvContent = "data:text/csv;charset=utf-8," + scheduleList.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  exportShifts = async () => {
      const user = await getAuthenticatedUser();
      if (!user) throw new Error('Not authenticated');
      let shifts: ShiftI[] = [];

      let shift = await getNextShift(user.id, SimpleDate.now(this.utcOffset + SimpleDate.dst(this.utcOffset)));
      let events = [];
      while (shift) {
        shifts[shifts.length] = shift;
        let teamName = (await getTeam(shift.teamId)).name;
        let shiftType = (await getShiftTypes(shift.teamId)).filter(x => x.id === shift.shiftTypeId)[0].name;
        let [year, month, otherStuff] = shift.startTime.isoString.split('-');
        let day = otherStuff.substr(0, 2);
        let hourString = otherStuff.substr(3, 2);
        let tempHour = parseInt(hourString) - (this.utcOffset + SimpleDate.dst(this.utcOffset));
        let hour = tempHour;
        if (tempHour >= 24) {
          hour = tempHour - 24;
          day = (parseInt(day) + 1).toString();
        }
          
        // let hour = tempHour >= 24 ? tempHour - 24 : tempHour;
        events.push({ title: shiftType, startInputType: 'utc', description: teamName, location: 'https://unc.zoom.us/j/97191695899?pwd=cERyWklHbkhQKzJjRko0V2lCZmVzQT09', start: [year, month, day, hour, 0], duration: { hours: 1 } });
        shift = await getNextShift(user.id, SimpleDate.fromSerialized(shift.endTime));
      }

      if (shifts.length <= 0) {
        console.log("no shifts");
        snackbar("No upcoming shifts to export")
      } else {
        const { err, value } = await ics.createEvents(events);
        if  (err) {
          console.log(err);
        } else {
          if (value !== null) {
            console.log(value);
            window.open( "data:text/calendar;charset=utf8," + escape( value ) );
          } else {
            console.log("null");
          }
        }
      }
  }

  render() {
    const { loadingEvents } = this.state
    return (
        <React.Fragment>
          <CalendarStyles />
          {!loadingEvents && <CalendarNav>
            <CalendarArrowButton onClick={this.prev}>
              <ArrowBack size={20} />
            </CalendarArrowButton>
            <CalendarArrowButton onClick={this.next}>
              <ArrowForward size={20} />
            </CalendarArrowButton>
            <CalendarToggleButton onClick={this.changeCalendar}>
              <EventNote size={20} />
              <ButtonLabel>{this.state.view === View.Individual ? "Full Schedule" : "Individual Schedule"}</ButtonLabel>
            </CalendarToggleButton>
            <CalendarToggleButton onClick={this.exportShifts}>
              <Export size={20} />
              <ButtonLabel>Export Shifts</ButtonLabel>
            </CalendarToggleButton>
            {/* Display download csv button if the user is an admin of any of their teams */}
            {this.context.user.memberships.find(m => m.isAdmin) !== undefined && 
                <CSVButton onClick={() => this.setState({menuIsOpen: true})}>
                  <ArrowDownward size={20} />
                  <ButtonLabel>Download CSV</ButtonLabel>
                </CSVButton>}
            <Menu
              open={this.state.menuIsOpen}
              onRequestClose={() => this.setState({menuIsOpen: false})}
              color="#9cdfff"
              items={[{key: 1, text: "Schedule", onSelect: this.downloadSchedule}, {key: 2, text: "Availability", onSelect: this.downloadPeopleAvailable}]}
            />
          </CalendarNav>}
        <CalendarContainer ref={this.calendarRef}></CalendarContainer>
        <ShiftModal
          open={this.state.showModal}
          shift={this.state.selectedShift}
          onStartSwap={this.startSwap}
          onNameClicked={this.nameClicked}
          onRequestClose={this.closeModal}
        />
      </React.Fragment>
    )
  }
}

const CalendarStyles = createGlobalStyle`
  .fc-time-grid .fc-slats tr {
    height: 50px;
  }
  .fc-time-grid .fc-axis.fc-time.fc-widget-content {
    vertical-align: top;
  }
  div.fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
    & .fc-left h2 {
      font-size: 16px;
      font-weight: normal;
      margin-left: 28px;
      width: fit-content;
      min-width: 97px;
      padding: 5px;
      border: 1px solid #d8d8d8;
      border-radius: 8px 8px 0 0;
      border-bottom: none;
      text-align: center;
    }
  }
  .${classNames.event} {
    font-size: 12px;
    margin: 0 3px 3px 0;
    transform: translateX(-3px);
    padding: 0 2px;
    cursor: pointer;
  }
  .${classNames.content} {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  .${classNames.title} {
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
  }
  .${classNames.time} {
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
  }
  .${classNames.workersCount} {
    font-size: 12px;
    text-align: left;
    white-space: nowrap;
  }
`
const CalendarContainer = styled.div`
  margin-bottom: 20px;
`
const CalendarNavButton = styled(Button)`
  background-color: #fff;
  font-size: 14px;
  color: #000;
  border: 1px solid lightgray;
  margin-right: 10px;
  :hover {
    background-color: #efefef;
  }
  :active {
    background-color: #d8d8d8;
  }
`
const ButtonLabel = styled.span`
  position: relative;
  margin-left: 10%;
`
const CalendarToggleButton = styled(CalendarNavButton)`
  justify-content: left;
  width: 190px;
`
const CSVButton = styled(CalendarToggleButton)`
  margin-right: 5px;
`
const CalendarArrowButton = styled(CalendarNavButton)`
  border-radius: 50%;
  padding: 9px;
`
const CalendarNav = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin: 20px 0 10px 0;
`