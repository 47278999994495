import React from 'react'
import styled from 'styled-components/macro'
import { AppContextComponent } from '../../client-utils'
import { BoxShadow } from '../../CommonStyles'
import ProfileModal from './ProfileModal';

interface Props {}
interface State {
  open: boolean
}

class NameButton extends AppContextComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  open = () => {
    this.setState({ open: true })
  }

  close = () => {
    this.setState({ open: false })
  }

  render() {
    const { open } = this.state
    const { user } = this.context
    return (
      <React.Fragment>
        <ButtonName onClick={this.open}>{user.firstName} {user.lastName}</ButtonName>
        <ProfileModal open={open} onRequestClose={this.close} />
      </React.Fragment>
    )
  }
}

export default NameButton

const ButtonName = styled.div`
  padding: 6px 8px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  right: 10px;
  bottom: 0px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  transform: translateY(50%);
`