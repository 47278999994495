import { swap } from "../apiRequest"
import { snackbar } from "./SnackbarController"
import { emit, AppEvent, onAppEvent } from "../AppEvents"
import { HttpStatus } from "../shared/utils";

class SwapController {
  shiftIdA: number
  shiftIdB: number
  isSwapping: boolean = false

  constructor() {
    onAppEvent(AppEvent.StartSwap, () => this.isSwapping = true)
    onAppEvent(AppEvent.EndSwap, () => this.isSwapping = false)
  }

  async executeSwap(personIdA: number, personIdB: number) {
    const res = await swap(this.shiftIdA, personIdA, this.shiftIdB, personIdB)
    if (res.status === HttpStatus.Ok) {
      // await indexController.forceRefresh()
      snackbar("Swap successful", "Undo", () => this.executeSwap(personIdB, personIdA))
      emit(AppEvent.EndSwap)
      emit(AppEvent.ScheduleUpdated)
    } else {
      snackbar(await res.text(), "Retry", () => this.executeSwap(personIdA, personIdB))
    }
    return res.status === HttpStatus.Ok
  }
}

const swapController = new SwapController()
export default swapController