import React from 'react'
import { PersonI, TeamI, ConstraintI } from "./shared/entity-interfaces"
import { Button, Spinner } from './components/ui-kit'
import styled  from 'styled-components/macro'
import { Colors } from './CommonStyles'
import { Star } from 'styled-icons/material';

export enum Keys {
  Enter = 13,
  Escape = 27,
}

interface AsyncButtonProps<T> {
  onClick: () => Promise<T>
  onResolved?: (result: T) => void
}
interface AsyncButtonState {
  loading: boolean
}
export class AsyncButton<T> extends React.Component<AsyncButtonProps<T>, AsyncButtonState> {
  constructor(props: AsyncButtonProps<T>) {
    super(props)
    this.state = {
      loading: false
    }
  }

  handleClick = async () => {
    this.setState({ loading: true })
    try {
      const result = await this.props.onClick()
      this.setState({ loading: false })
        if (this.props.onResolved) {
          this.props.onResolved(result)
        }
    } catch (e) {
      console.error(e)
      this.setState({ loading: false })
    }
  }

  render() {
    const { loading } = this.state
    return (
      <ButtonContainer>
        <Button onClick={this.handleClick} disabled={loading}>{this.props.children}</Button>
        {loading && <AsyncButtonSpinner />}
      </ButtonContainer>
    )
  }
}

export const PageLoader = () => (
  <PageLoaderContainer>
    <PageLoaderText>Loading...</PageLoaderText>
  </PageLoaderContainer>
)

const PageLoaderText = styled.span`
  font-size: 16px;
`
const PageLoaderContainer = styled.div`
  margin-top: 30px;
  font-family: sans-serif;
  text-align: center;
`

const AsyncButtonSpinner = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

const SpinnerContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const ButtonContainer = styled.div`
  display: inline-block;
  position: relative;
  color: #fff;
`

export interface AppContextI {
  user: PersonI
}
export const AppContext = React.createContext<AppContextI>({ user: null! })

export class AppContextComponent<Props = {}, State = {}> extends React.Component<Props, State> {
  static contextType = AppContext
  context: React.ContextType<typeof AppContext>
}

export class AppContextPureComponent<Props = {}, State = {}> extends React.PureComponent<Props, State> {
  static contextType = AppContext
  context: React.ContextType<typeof AppContext>
}

export const getConstraintKey = (constraint: ConstraintI) => `${constraint.shiftTypeId}${constraint.attributeId}`

export function withLoader<LoadedProps = {}, PassedProps = {}>(Component: React.ComponentType<LoadedProps & PassedProps>, loader: JSX.Element, fetchProps: (context?: AppContextI, props?: PassedProps) => Promise<LoadedProps>) {
  return class extends AppContextComponent<PassedProps, { loading: boolean, shouldShowLoader: boolean, loadedProps: LoadedProps | null }> {
    constructor(props: PassedProps) {
      super(props)
      this.state = {
        loading: true,
        shouldShowLoader: false,
        loadedProps: null,
      }
      // start showing the spinner after half a second
      setTimeout(() => this.setState({ shouldShowLoader: true }), 500)
    }

    async componentDidMount() {
      const loadedProps = await fetchProps(this.context, this.props)
      this.setState({ loading: false, loadedProps })
    }

    render() {
      const { loading, shouldShowLoader } = this.state
      if (!loading) {
        return <Component {...this.props} {...this.state.loadedProps} />
      } else if (shouldShowLoader) {
        return loader
      }
      return null
    }
  }
}

export const AttributeBubble = styled.div`
  padding: 1px 5px 1px 5px;
  background-color: ${Colors.AttributePurple};
  border-radius: 20px;
  border: 1px solid #b07676;
  color: #000;
  font-size: 14px;
  width: fit-content;
  display: inline-block;
`
const AdminTagBubble = styled(AttributeBubble)`
  background-color: ${Colors.AdminGreen};
  border-color: #32b36c;
`
const AdminStar = styled(Star)`
  margin-right: 2px;
`
export const AdminTag = (props: { className?: string, starSize?: number }) => (
  <AdminTagBubble className={props.className}><AdminStar size={props.starSize || 12} />Admin</AdminTagBubble>
)