import { stringifyDatePart, indexToMonthShort, indexToWeekdayName, indexToWeekdayShortName } from './date-utils'
import { ValueTransformer } from 'typeorm/decorator/options/ValueTransformer'
import { differenceInCalendarDays as dateFnsDifferenceInCalendarDays, formatDistance as dateFnsDifferenceInWords } from 'date-fns'
import { monthNumberToName } from '../shared/date-utils';

/**
 * Dealing with timezones is hard. This class tries to make it easier for this app
 * Here are some important things to know:
 * - In this app, the team creator picks a timezone for the team, and then
 * all the dates across the entire application, including the db,
 * are handled in that timezone. So we never want to use the user's computer's timezone,
 * or the server's timezone for anything. We always want to specify it ourselves.
 * - the native getHours, getDate etc. methods on date objects, as well as the new Date() constructor,
 * return dates in the operating system's timezone. So this is bad and we never want to use these.
 * Instead, here we create a UTC based date object, and use the UTC versions of the methods
 * to get the properties we want. Typeorm will not convert the date at all if it is in UTC,
 * so we can just give typeorm that date object.
 * client-side SimpleDate -> SerializedDate -internet-> (typeorm) -> SerializedDate -> Date -> MySQL datetime
 */

export interface SerializedDate {
  isoString: string
}

export default class SimpleDate {
  static fromValues = (year: number, month: number, dateOfMonth: number, hours: number = 0, minutes: number = 0, seconds: number = 0, ms: number = 0) => {
    return SimpleDate.fromMs(Date.UTC(year, month - 1, dateOfMonth, hours, minutes, seconds, ms))
  }

  static fromMs = (ms: number) => {
    return new SimpleDate(new Date(ms))
  }

  static fromSerialized = (serializedDate: SerializedDate) => {
    return new SimpleDate(new Date(serializedDate.isoString))
  }

  static fromUTCDate = (date: Date) => {
    return new SimpleDate(date)
  }

  static fromUTCDateString = (dateString: string) => {
    if (!dateString.endsWith('Z')) throw new Error('date string was not in UTC')
    return new SimpleDate(new Date(dateString))
  }

  static now = (utcOffset: number) => {
    const utc = new Date(Date.now())
    return new SimpleDate(utc).addHours(utcOffset)
  }

  static localNow = () => {
    const local = new Date()
    return SimpleDate.fromValues(local.getFullYear(), local.getMonth() + 1, local.getDate(), local.getHours(), local.getMinutes(), local.getSeconds(), local.getMilliseconds())
  }

  static dst = (utcOffset: number) => {
    const date = SimpleDate.now(utcOffset);
    return date.dstOffset;
  }

  private constructor(private date: Date) {
    if (!date) throw new Error('tried to construct SimpleDate with no initial date')
  }

  serialize = (): SerializedDate => {
    return { isoString: this.date.toISOString() }
  }

  toString = () => {
    const utcString = this.date.toUTCString()
    // remove ' GMT' from end
    return utcString.substring(0, utcString.length - 4)
  }

  toMediumString = () => {
    const utcString = this.date.toUTCString()
    return utcString.substring(0, utcString.length - 13)
  }

  toShortString = () => {
    return `${this.monthNumber}/${this.dateOfMonth}`
  }

  toMs = () => {
    return this.date.getTime()
  }

  toISOString = () => {
    return this.date.toISOString()
  }

  cloneAndApply = (fn: Function, ...args: any[]) => {
    const newDate = this.clone()
    newDate.date = fn(newDate.date, ...args)
    return newDate
  }

  clone = () => {
    return SimpleDate.fromMs(this.toMs())
  }

  get year() {
    return this.date.getUTCFullYear()
  }

  get monthNumber() {
    return this.date.getUTCMonth() + 1
  }

  get monthString() {
    return monthNumberToName(this.monthNumber)
  }

  get dateOfMonth() {
    return this.date.getUTCDate()
  }

  get weekdayIndex() {
    return this.date.getUTCDay()
  }

  get weekdayString() {
    return indexToWeekdayName(this.weekdayIndex)
  }

  get weekdayStringShort() {
    return indexToWeekdayShortName(this.weekdayIndex)
  }

  get hours() {
    return this.date.getUTCHours()
  }

  get hoursAmPm() {
    return this.hours > 12 ? this.hours - 12 : this.hours
  }

  get minutes() {
    return this.date.getUTCMinutes()
  }

  get seconds() {
    return this.date.getUTCSeconds()
  }

  get milliseconds() {
    return this.date.getUTCMilliseconds()
  }

  get dayOfWeek() {
    return this.date.getDay()
  }


  get dstOffset() {
    // kudo: https://stackoverflow.com/questions/5590429/calculating-daylight-saving-time-from-only-date
    const day = this.dateOfMonth;
    const dow = this.dayOfWeek;
    const month = this.monthNumber;
    if (month < 3 || month > 11) return 0;
    if (month > 3 && month < 11) return 1;
    const prevSunday = day - dow;
    if (month == 3) return (prevSunday >= 8 ? 1 : 0);
    return (prevSunday <= 0 ? 1 : 0);
  }

  getFormattedDateString = () => {
    return `${this.weekdayString} ${indexToMonthShort(this.monthNumber)} ${this.dateOfMonth}`
  }

  getFormattedTimeString = () => {
    let hour = this.hours
    let label = 'am'
    if (hour === 0) {
      hour = 12
    } else if (hour === 12) {
      label = 'pm'
    } else if (hour > 12) {
      hour -= 12
      label = 'pm'
    }
    return `${hour}:${stringifyDatePart(this.minutes)}${label}`
  }

  getShortTimeString = () => {
    if (this.minutes === 0) {
      return this.hoursAmPm
    }
    return `${this.hoursAmPm}:${stringifyDatePart(this.minutes)}`
  }

  toUTCDate = () => {
    return this.date
  }

  addHours = (n: number) => {
    const newDate = this.clone()
    newDate.date.setUTCHours(this.hours + n)
    return newDate
  }

  subHours = (n: number) => {
    return this.addHours(-n)
  }

  addDays = (n: number) => {
    const newDate = this.clone()
    newDate.date.setUTCDate(newDate.dateOfMonth + n)
    return newDate
  }

  subDays = (n: number) => {
    return this.addDays(-n)
  }

  startOfDay = () => {
    const newDate = this.clone()
    newDate.date.setUTCHours(0)
    newDate.date.setUTCMinutes(0)
    newDate.date.setUTCSeconds(0)
    newDate.date.setUTCMilliseconds(0)
    return newDate
  }

  lastSundayOrToday = () => {
    return this.startOfDay().subDays(this.weekdayIndex)
  }

  lastSundayNotToday = () => {
    const daysSinceSunday = this.weekdayIndex === 0 ? 7 : this.weekdayIndex
    return this.startOfDay().subDays(daysSinceSunday)
  }

  nextSundayOrToday = () => {
    const daysUntilSunday = this.weekdayIndex === 0 ? 0 : 7 - this.weekdayIndex
    return this.startOfDay().addDays(daysUntilSunday)
  }

  nextSundayNotToday = () => {
    const daysUntilSunday = 7 - this.weekdayIndex
    return this.startOfDay().addDays(daysUntilSunday)
  }

  distanceInWords = (date: SimpleDate) => {
    return dateFnsDifferenceInWords(this.date, date.date)
  }

  distanceInDays = (date: SimpleDate) => {
    return dateFnsDifferenceInCalendarDays(this.date, date.date)
  }

  compare = (date: SimpleDate) => {
    return this.toMs() - date.toMs()
  }

  isBefore = (date: SimpleDate) => {
    return this.compare(date) < 0
  }

  isAfter = (date: SimpleDate) => {
    return this.compare(date) > 0
  }

  rangeToArray = (date: SimpleDate): SimpleDate[] => {
    let days: SimpleDate[] = []
    for (let i = 0; i <= date.dateOfMonth - this.dateOfMonth; i++) {
      days.push(this.addDays(i))
    }
    return days
  }
}

export let serializeDate = (date: Date): SerializedDate => {
  return { isoString: date.toISOString() }
}

export class SerializedDateTransformer implements ValueTransformer {
  // Store SimpleDate objects in database as datetime in utc
  to(serializedDate: SerializedDate): Date {
    return new Date(serializedDate.isoString)
  }

  // Can't construct a new SimpleDate object here - we need something sendable in json
  from(date: Date): SerializedDate {
    return serializeDate(date)
  }
}
