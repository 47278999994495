export function indexToWeekdayShortName(index: number) {
  switch (index) {
    case 0:
      return 'Sun'
    case 1:
      return 'Mon'
    case 2:
      return 'Tue'
    case 3:
      return 'Wed'
    case 4:
      return 'Thu'
    case 5:
      return 'Fri'
    case 6:
      return 'Sat'
    default:
      throw new Error(`invalid weekday index: ${index}`)
  }
}

export function indexToWeekdayName(index: number) {
  switch (index) {
    case 0:
      return 'Sunday'
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    default:
      throw new Error(`invalid weekday index: ${index}`)
  }
}

export function indexToMonthShort(index: number) {
  switch (index) {
    case 1:
      return 'Jan'
    case 2:
      return 'Feb'
    case 3:
      return 'Mar'
    case 4:
      return 'Apr'
    case 5:
      return 'May'
    case 6:
      return 'Jun'
    case 7:
      return 'Jul'
    case 8:
      return 'Aug'
    case 9:
      return 'Sept'
    case 10:
      return 'Oct'
    case 11:
      return 'Nov'
    case 12:
      return 'Dec'
    default:
      throw new Error(`invalid month index: ${index}`)
  }
}

export function monthNumberToName(index: number) {
  switch (index) {
    case 1:
      return 'January'
    case 2:
      return 'February'
    case 3:
      return 'March'
    case 4:
      return 'April'
    case 5:
      return 'May'
    case 6:
      return 'June'
    case 7:
      return 'July'
    case 8:
      return 'August'
    case 9:
      return 'September'
    case 10:
      return 'October'
    case 11:
      return 'November'
    case 12:
      return 'December'
    default:
      throw new Error(`invalid month index: ${index}`)
  }
}

export let isValidTimezoneOffsetString = (input: string) => {
  const s = input.replace(':', '')
  if (s.length !== 5) return false
  const sign = s.substring(0, 1)
  if (sign !== '-' && sign !== '+') return false
  if (isNaN(parseInt(s.substring(1), 10))) {
    return false
  }
  return true
}

export let stringifyDatePart = (input: number | string) => {
  if (typeof input === 'number') {
    input = input.toString()
  }
  if (input.length > 2) throw new Error(`Number has more than 2 digits: ${input}`)
  if (input.length === 2) {
    return input
  } else if (input.length === 1) {
    return '0' + input
  }
  throw new Error(`Something went wrong with this input: ${input}`)
}

export let hasTimezonePart = (s: string) => {
  const cleaned = s.replace(':', '')
  const timezonePart = cleaned.substring(cleaned.length - 5)
  return isValidTimezoneOffsetString(timezonePart)
}

export function timezoneOffsetStringToNumberHours(s: string) {
  s = s.replace(':', '')
  if (s.length !== 5) throw new Error(`Invalid timezone offset string: ${s}`)
  const sign = s.substring(0, 1)
  if (sign !== '-' && sign !== '+') throw new Error(`Invalid timezone offset string: ${s}`)
  const hours = parseInt(s.substring(1, 3), 10)
  const minutes = parseInt(s.substring(3, 5), 10) / 60
  let time = hours + minutes
  if (sign === '-') {
    time = -time
  }
  return time
}

export function numberToHourLabel(hour: number) {
  if (hour < 0 || hour > 24) throw new Error(`invalid hour number: ${hour}`)
  let amPmLabel = 'am'
  if (hour > 12) {
    hour -= 12
    amPmLabel = 'pm'
  }
  amPmLabel = hour === 12 ? 'pm' : amPmLabel
  return hour + amPmLabel
}