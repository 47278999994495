import SimpleDate from "./shared/SimpleDate"
import { ShiftI, ShiftTypeI, PersonI, AttributeI, AvailabilityI, TeamI, ConstraintI, MembershipI } from "./shared/entity-interfaces"
import { HttpStatus } from "./shared/utils"
import { EventInput } from '@fullcalendar/core'

const dev = process.env.NODE_ENV !== 'production' || window.location.href.startsWith('https://shiftoverflow.33.33.33.14.nip.io')
const localDev = process.env.REACT_APP_LOCAL_DEV

const devSiteUrl = localDev ? 'http://localhost:8080' : 'https://shiftoverflow.33.33.33.14.nip.io'
const siteUrl = dev ? devSiteUrl : 'https://shiftoverflow.com'

export let addToShift = (shiftId: number, personId: number): Promise<Response> => {
  return postRequest('/addToShift', { shiftId, personId })
}

export let dropFromShift = (shiftId: number, personId: number): Promise<Response> => {
  return postRequest('/dropFromShift', { shiftId, personId })
}

export let swap = (shiftIdA: number, personIdA: number, shiftIdB: number, personIdB: number): Promise<Response> => {
  return postRequest('/swap', {
    shiftIdA,
    personIdA,
    shiftIdB,
    personIdB
  })
}


export let updateAvailability = (startDate: SimpleDate, personId: number, isAvailable: boolean, teamId: number): Promise<Response> => {
  return postRequest('/updateAvailability', { startTime: startDate.serialize(), personId, isAvailable, teamId })
}

export let copyAvailabilityToNextWeek = (personId: number, teamId: number, startDate: SimpleDate, endDate: SimpleDate) => {
  return postRequest('/copyAvailabilityToNextWeek', { personId, teamId, startDate: startDate.serialize(), endDate: endDate.serialize() })
}

// export let updateShifts = (shiftType: string, startDate: SimpleDate, isSelected: boolean): Promise<Response> => {
//   return postRequest('/updateShifts', {shiftType, startTime: startDate.serialize(), isSelected})
// }

export let getAuthenticatedUser = async (): Promise<PersonI> => {
  const res = await getRequest('/authedUser')
  if (res.status === HttpStatus.Ok) {
    const jsonRes = await res.json()
    return jsonRes.user
  }
  throw new Error('Unauthorized')
}

// find people who have 0 avail, copy their avail, and report back who has:
// 1) less than weekly availability
// 2) less than 2x weekly availability
export let handlePeopleLackingAvailability= async (startTime: SimpleDate, endTime: SimpleDate, teamId: number): Promise<string> => {
  const res = await postRequest('/handlePeopleLackingAvailability', {startDate: startTime.serialize(), endDate: endTime.serialize(), teamId: teamId})
  const text = await res.text()
  return text;
}

export let logout = async () => {
  await getRequest('/logout')
}

let getPostRequestResult = async <T> (endpoint: string, args: Object, errorVal: T): Promise<T> => {
  try {
    const res = await postRequest(endpoint, args)
    if (res.status === HttpStatus.Ok) {
      const parsedRes = await res.json()
      const result = parsedRes.result as T
      if (result === undefined) {
        throw new Error(`Could not get property 'result' of response json for endpoint: ${endpoint}`)
      }
      return result
    } else if (res.status === HttpStatus.Unauthorized) {
      throw new Error('Unauthorized')
    } else {
      throw new Error(`Server error. Status code: ${res.status}`)
    }
  } catch (e) {
    console.error(`${e}\n - endpoint: ${endpoint}`)
    // TODO: add report option
    return errorVal
  }
}

export let getShiftTypes = async (teamId: number) => {
  return getPostRequestResult<ShiftTypeI[]>('/getShiftTypes', { teamId }, [])
}

export let getTeam = async (id: number) => {
  return getPostRequestResult<TeamI | null>('/getTeam', { id }, null)
}

export let getMemberships = async (personId: number) => {
  return getPostRequestResult<MembershipI[]>('/getMemberships', { personId }, [])
}

export let addShiftType = async ({ name, minStaffing, maxStaffing }: { name: string, minStaffing: number, maxStaffing: number }, teamId: number) => {
  return getPostRequestResult<ShiftTypeI | null>('/addShiftType', { name, minStaffing, maxStaffing, teamId }, null)
}

export let deleteShiftType = async (id: number) => {
  return postRequest('/deleteShiftType', { id })
}

export let updateShiftTypeName = async (id: number, name: string) => {
  return postRequest('/updateShiftTypeName', { id, name })
}

export let updateShiftTypeMinStaffing = async (id: number, value: number) => {
  return postRequest('/updateShiftTypeMinStaffing', { id, value })
}

export let updateShiftTypeMaxStaffing = async (id: number, value: number) => {
  return postRequest('/updateShiftTypeMaxStaffing', { id, value })
}

export let getConstraints = async (shiftTypeId: number) => {
  return getPostRequestResult<ConstraintI[]>('/getConstraints', { shiftTypeId }, [])
}

export let addConstraint = async (shiftTypeId: number, attributeId: number, minStaffing: number) => {
  return postRequest('/addConstraint', { shiftTypeId, attributeId, minStaffing })
}

export let deleteConstraint = async (shiftTypeId: number, attributeId: number) => {
  return postRequest('/deleteConstraint', { shiftTypeId, attributeId })
}

export let updateConstraintMinStaffing = async (shiftTypeId: number, attributeId: number, minStaffing: number) => {
  return postRequest('/updateConstraintMinStaffing', { shiftTypeId, attributeId, minStaffing })
}

export let getShifts = async (teamId: number) => {
  return getPostRequestResult<ShiftI[]>('/getShifts', { teamId }, [])
}

export let getUsers = async (teamId: number): Promise<PersonI[]> => {
  return getPostRequestResult<PersonI[]>('/getUsers', { teamId }, [])
}

export let getShiftInfo = async (shiftId: number, teamId: number) => {
  return getPostRequestResult<PersonI[]>('/getWorkersForShift', { shiftId: 1, teamId: 1 }, [])
}

export let getAvailablePeople = async (teamId: number, startTime: string, endTime: string): Promise<PersonI[]> => {
  return getPostRequestResult<PersonI[]>('/getAvailablePeople', { teamId, startTime, endTime }, []);
}

export let getScheduledShifts = async (personId: number, start: SimpleDate, end: SimpleDate): Promise<ShiftI[]> => {
  return getPostRequestResult<ShiftI[]>('/getScheduledShifts', { personId, start: start.serialize(), end: end.serialize() }, [])
}

export let getScheduledShiftsCount = async (personId: number, start: SimpleDate, end: SimpleDate, teamId: number): Promise<number> => {
  return getPostRequestResult<number>('/getScheduledShiftsCount', { personId, start: start.serialize(), end: end.serialize(), teamId }, 0)
}

export let getUserAttributes = async (personId: number): Promise<AttributeI[]> => {
  return getPostRequestResult<AttributeI[]>('/getUserAttributes', { personId }, [])
}

export let getAllAttributes = async (teamId: number): Promise<AttributeI[]> => {
  return getPostRequestResult<AttributeI[]>('/getAllAttributes', { teamId }, [])
}

export let addPersonAttribute = async (personId: number, attributeId: number): Promise<Response> => {
  return postRequest('/addPersonAttribute', { personId, attributeId })
}

export let removePersonAttribute = async (personId: number, attributeId: number): Promise<Response> => {
  return postRequest('/removePersonAttribute', { personId, attributeId })
}

// export let updateUserAttribute = async (attr: AttributeI): Promise<Response> => {
//   return postRequest('/updateUserAttribute', {attr})
// }

export let updateWeeklyShifts = async (personId: number, teamId: number, value: number): Promise<Response> => {
  return postRequest('/updateWeeklyShifts', { personId, teamId, value })
}

export let generateAndLoadSchedule = async (dates: SimpleDate[], shiftTypeIds: number[], teamId: number): Promise<Response> => {
  return postRequest('/generateSchedule', {
    dates: dates.map(sd => sd.serialize()),
    shiftTypeIds,
    teamId
  })
}

export let getCalendarEvents = async (start: SimpleDate, end: SimpleDate, teamIds: number[]): Promise<EventInput[]> => {
  return getPostRequestResult<EventInput[]>('/getCalendarEvents', { start: start.serialize(), end: end.serialize(), teamIds }, [])
}

export let getNextShift = async (personId: number, start: SimpleDate) => {
  return getPostRequestResult<ShiftI | null>('/getNextShift', { personId, start: start.serialize() }, null)
}

export let getAvailability = async (personId: number, start: SimpleDate, end: SimpleDate, teamId: number): Promise<AvailabilityI[]> => {
  return getPostRequestResult<AvailabilityI[]>('/getAvailability', { personId, start: start.serialize(), end: end.serialize(), teamId }, [])
}

export let getAvailabilityCount = async (personId: number, start: SimpleDate, end: SimpleDate, teamId: number): Promise<number> => {
  return getPostRequestResult<number>('/getAvailabilityCount', { personId, start: start.serialize(), end: end.serialize(), teamId }, 0)
}

export let getShiftsForTeam = async (start: SimpleDate, end: SimpleDate, teamId: number): Promise<ShiftI[]> => {
  return getPostRequestResult<ShiftI[]>('/getShiftsForTeam', {start: start.serialize(), end: end.serialize(), teamId}, [])
}

export let createShiftsForRange = async (start: SimpleDate, end: SimpleDate, shiftTypeId: number): Promise<boolean> => {
  return getPostRequestResult<boolean>('/createShiftsForRange', { start: start.serialize(), end: end.serialize(), shiftTypeId}, false)
}

export let deleteShift = async (shiftId: number) => {
  return postRequest('/deleteShift', { shiftId })
}

export let getRequest = async (url: string): Promise<Response> => {
  const httpInfo = {
    method: 'GET'
 }
  return fetch(siteUrl + url, httpInfo)
}

export let postRequest = async (url: string, data: {}): Promise<Response> => {
  const httpInfo = {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(data)
  }
  return fetch(siteUrl + url, httpInfo)
}
