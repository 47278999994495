import Snackbar from '../components/Snackbar'

class SnackbarController {
  snackbarComponent: React.RefObject<Snackbar>

  showSnackbar(message: string, actionText?: string, actionHandler?: Function) {
    if (this.snackbarComponent && this.snackbarComponent.current) {
      this.snackbarComponent.current.cancelTimeout()
      this.snackbarComponent.current.show({ message, actionText, actionHandler })
    }
  }

  registerSnackbarComponent(component: React.RefObject<Snackbar>) {
    this.snackbarComponent = component
  }
}

const snackbarController = new SnackbarController()
export default snackbarController

export function snackbar(message: string, actionText?: string, actionHandler?: Function) {
  snackbarController.showSnackbar(message, actionText, actionHandler)
}