import React, { Suspense } from 'react'
import Snackbar from './Snackbar'
import snackbarController from '../controllers/SnackbarController'
import { getAuthenticatedUser, getMemberships } from '../apiRequest'
import LoginPage from './LoginPage'
import HomePage from './HomePage/HomePage'
import { PersonI } from '../shared/entity-interfaces'
import { onAppEvent, AppEvent } from '../AppEvents'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { AppContext, PageLoader } from '../client-utils'
const AdminPage = React.lazy(() => import('./AdminPage/AdminPage'))
const AvailabilityPage = React.lazy(() => import('./AvailabilityPage'))

enum View {
  Login,
  Home,
  Availability,
  Admin,
}

interface Props {}
interface State {
  loading: boolean
  view: View
  user: PersonI
}

export default class App extends React.Component<Props, State> {
  snackbarRef = React.createRef<Snackbar>()

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      view: View.Home,
      user: null,
    }
    onAppEvent(AppEvent.GoToHomePage, () => this.setState({ view: View.Home }))
    onAppEvent(AppEvent.GoToAvailabilityPage, () => this.setState({ view: View.Availability }))
    onAppEvent(AppEvent.GoToAdminPage, () => this.setState({ view: View.Admin }))
    onAppEvent(AppEvent.RecheckAuth, this.checkIsAuthed)
  }

  componentDidMount() {
    snackbarController.registerSnackbarComponent(this.snackbarRef)
    this.checkIsAuthed()
  }

  checkIsAuthed = async () => {
    try {
      const user = await getAuthenticatedUser()
      // throw before trying to get team, otherwise getTeam will error and cause snackbar
      if (!user) throw new Error('Not authenticated')
      const memberships = await getMemberships(user.id)
      if (memberships.length < 1) throw new Error('User doesn\'t belong to any team :(');
      user.memberships = memberships
      this.setState({ loading: false, view: View.Home, user })
    } catch (e) {
      // failed to get authenticated user, redirect to login
      this.setState({ loading: false, view: View.Login })
    }
  }

  render() {
    const { loading, user } = this.state
    let { view } = this.state

    let viewComponent = <PageLoader />
    if (!loading) {
      // ensure that user will never be null in the context provider below (except to render login view)
      if (!user) {
        view = View.Login
      }
      if (view === View.Login) {
        viewComponent = <LoginPage />
      } else if (view === View.Home) {
        viewComponent = <HomePage />
      } else if (view === View.Availability) {
        viewComponent = <AvailabilityPage />
      } else if (view === View.Admin) {
        viewComponent = <AdminPage />
      }
    }

    return (
      <React.Fragment>
        <AppContainer>
          <Suspense fallback={<PageLoader />}>
            <AppContext.Provider value={{ user }}>
              { viewComponent }
              <Snackbar ref={this.snackbarRef} />
            </AppContext.Provider>
          </Suspense>
        </AppContainer>
        <GlobalStyle />
      </React.Fragment>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #fafafa;
    /* TODO: pick actual fonts */
    font-family: sans-serif;
  }

  *, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    border: 0;
  }

  #root {
    height: 100%;
  }

  @media print {
    body {
      background-color: #fff
    }
  }
`

const AppContainer = styled.div`
  text-align: center;
  height: 100%;
`