import React from 'react'
import { getRequest, postRequest } from '../apiRequest'
import { snackbar } from '../controllers/SnackbarController'
import styled from 'styled-components/macro'
import { AsyncButton, Keys } from '../client-utils'
import { Input as BaseInput } from './ui-kit'
import { HttpStatus } from '../shared/utils';
import { emit, AppEvent } from '../AppEvents';

interface LoginState {
  email: string
  password: string
  repeatPassword: string
  mode: LoginMode
  resettingPassword: boolean
  inviteCode: string
  firstName: string
  lastName: string
  resetPasswordToken: string
}

enum LoginMode {
  signIn = "Sign In",
  signUp = "Sign Up",
  resetPassword = "Reset Password"
}

export default class LoginPage extends React.Component<{}, LoginState> {
  constructor(props: any) {
    super(props)
    this.state = {
      email: '',
      password: '',
      repeatPassword: '',
      mode: LoginMode.signIn,
      resettingPassword: false,
      inviteCode: '',
      firstName: '',
      lastName: '',
      resetPasswordToken: ''
    }
  }

  login = (email: string, password: string): Promise<Response | void> => {
    if (!this.validate(this.state.email, this.state.password)) {
      snackbar("Please enter an email and password")
      return Promise.resolve()
    }
    let data = { email, password }
    return postRequest('/login', data)
      .then(res => {
        if (res.status === HttpStatus.Ok) {
          emit(AppEvent.RecheckAuth)
        } else if (res.status === HttpStatus.Unauthorized) {
          this.setState({ password: '' })
          snackbar("Incorrect username or password")
        } else {
          this.setState({ password: '' })
          snackbar("System error, please speak to your system administrator")
        }
      }).catch(err => console.log(err))
  }

  logout = () => {
    getRequest('/logout')
      .then(res => {
        return res.json()
      }).then(data => console.log(data))
        .catch(err => console.log(err))
  }

  signup = () => {
    const { firstName, lastName, password, repeatPassword, inviteCode } = this.state
    const email = this.state.email.trim()

    if (!this.validate(email, password)) {
      snackbar("Please enter an email and password")
      return
    } else if (password !== repeatPassword) {
      snackbar("Passwords do not match, please try again.")
      return
    }
    let data = {
      firstName,
      lastName,
      email,
      password,
      inviteCode
    }
    postRequest('/createPersonForTeam', data).then(res => {
      if (res.status === HttpStatus.Created) {
        this.login(data.email, data.password)
        snackbar(`Welcome, ${data.firstName}!`)
      } else {
        snackbar(res.statusText)
      }
    }).catch(err => console.log(err))
  }

  resetPassword = () => {
    if (this.state.resetPasswordToken) {
      let data = {
        email: this.state.email,
        password: this.state.password,
        resetPasswordToken: this.state.resetPasswordToken
      }
      postRequest('/changePassword', data)
        .then(res => {
          snackbar(res.statusText)
          const successfulReset = res.status === HttpStatus.Ok
          this.setState({
            password: '',
            resetPasswordToken: successfulReset ? '' : this.state.resetPasswordToken,
            resettingPassword: successfulReset ? false : true,
            mode: successfulReset ? LoginMode.signIn : this.state.mode
          })
        })
        .catch(err => console.log(err))
    } else {
    postRequest('/resetPassword', { email: this.state.email })
      .then(res => {
        snackbar(res.statusText)
        if (res.status === 200) {
          this.setState({ resettingPassword: true })
        }
      }).catch(err => console.log(err))
    }
  }

  validate = (email: string, password: string): boolean => {
    if (email.length === 0) return false
    if (password.length === 0) return false
    return true
  }

  doAction = async () => {
    if (this.state.mode === LoginMode.signIn) {
      await this.login(this.state.email.trim(), this.state.password)
    } else if (this.state.mode === LoginMode.signUp) {
      this.signup()
    } else {
      this.resetPassword()
    }
  }

  handleFormSubmitted = (event: React.FormEvent) => {
    event.preventDefault()
    this.doAction()
  }

  handleKeydown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.keyCode === Keys.Enter) {
      event.preventDefault()
      this.doAction()
    }
  }

  firstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.currentTarget.value
    this.setState({ firstName })
  }

  lastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.currentTarget.value
    this.setState({ lastName })
  }

  emailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.currentTarget.value
    this.setState({ email })
  }

  passwordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.currentTarget.value
    this.setState({ password })
  }

  repeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const repeatPassword = event.currentTarget.value
    this.setState({ repeatPassword })
  }

  resetPasswordTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resetPasswordToken = event.currentTarget.value
    this.setState({ resetPasswordToken })
  }

  inviteCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inviteCode = event.currentTarget.value
    this.setState({ inviteCode })
  }

  render() {
    return (
      <FlexWrapContainer>
        <Form onSubmit={this.handleFormSubmitted} onKeyDown={this.handleKeydown}>
          <Title>ShiftOverflow</Title>
          <LabelContainer>
            <Label selected={this.state.mode === LoginMode.signIn} onClick={() => this.setState({mode: LoginMode.signIn})}>Sign in</Label>
            <Label selected={this.state.mode === LoginMode.signUp} onClick={() => this.setState({mode: LoginMode.signUp})}>Sign up</Label>
            <Label selected={this.state.mode === LoginMode.resetPassword} onClick={() => this.setState({mode: LoginMode.resetPassword})}>Reset Password</Label>
          </LabelContainer>
          {this.state.mode === LoginMode.signUp && <Input placeholder="First Name" value={this.state.firstName} onChange={this.firstNameChange}/>}
          {this.state.mode === LoginMode.signUp && <Input placeholder="Last Name" value={this.state.lastName} onChange={this.lastNameChange}/>}
          <Input placeholder="Email" value={this.state.email} onChange={this.emailChange} />
          {this.state.mode !== LoginMode.resetPassword && <Input placeholder="Password" type="password" value={this.state.password} onChange={this.passwordChange} />}
          {this.state.mode === LoginMode.signUp && <Input type="password" placeholder="Repeat Password" value={this.state.repeatPassword} onChange={this.repeatPasswordChange}/>}
          {this.state.mode === LoginMode.signUp && <Input placeholder="Invite Code" value={this.state.inviteCode} onChange={this.inviteCodeChange} />}
          {this.state.resettingPassword && this.state.mode === LoginMode.resetPassword && <Input placeholder="New Password" type="password" value={this.state.password} onChange={this.passwordChange} />}
          {this.state.resettingPassword && this.state.mode === LoginMode.resetPassword && <Input placeholder="Reset Password Token" value={this.state.resetPasswordToken} onChange={this.resetPasswordTokenChange} /> }
          <SubmitButtonContainer>
            <AsyncButton onClick={this.doAction}>{this.state.mode}</AsyncButton>
          </SubmitButtonContainer>
        </Form>
      </FlexWrapContainer>
    )
  }
}

const LabelContainer = styled.div`
  margin-bottom: 10px;
`
const Label = styled.label`
  padding: 5px;
  position: relative;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  transition: 300ms ease;
  color: ${(props: { selected: boolean }) => props.selected ? '#fff' : '#568cd3'};
`
const SubmitButtonContainer = styled.div`
  position: relative;
  margin-top: 20px;
`
const FlexWrapContainer = styled.div`
  display: flex;
  height: 100%;
  padding-top: 10%;
  background: #7BAFD4;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: center;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Input = styled(BaseInput)`
  background-color: #fff;
  border-radius: 5px;
  width: 220px;
  margin: 3px 0;
  & input {
    padding: 10px;
  }
`
const Title = styled.h1`
  color: #fff;
`