import React from 'react'
import styled from 'styled-components/macro'
import { AttributeI, MembershipI } from '../../shared/entity-interfaces'
import { AppContextComponent, AttributeBubble, withLoader, PageLoader, AdminTag } from '../../client-utils'
import { Modal, Button } from '.././ui-kit'
import SimpleDate from '../../shared/SimpleDate'
import { getUserAttributes, logout, getMemberships } from '../../apiRequest'

interface LoadedProps {
  memberships: MembershipI[]
  attributes: AttributeI[]
}
interface PassedProps {
  open: boolean
  onRequestClose: () => void
}
type Props = LoadedProps & PassedProps

class ProfileModal extends AppContextComponent<Props> {

  handleSignOutClicked = async () => {
    await logout()
    window.location.reload()
  }

  render() {
    const { open, onRequestClose, attributes, memberships } = this.props
    const { user } = this.context
    return (
      <Modal open={open} onRequestClose={onRequestClose}>
        <Content>
          <Name>{user.firstName} {user.lastName}</Name>
          <Email>{user.email}</Email>
          {memberships.map(membership => (
            <React.Fragment key={membership.id}>
              <Info>{membership.team.name}</Info>
              <Attributes>
                {membership.isAdmin && <AdminTag />}
                {attributes.length > 0 &&
                  attributes.filter(a => a.teamId === membership.teamId).map(attribute => <AttributeBubble key={attribute.id}>{attribute.description}</AttributeBubble>)
                }
              </Attributes>
              <Info>Weekly shifts allocated: {membership.weeklyShifts}</Info>
              <Info>Member since: {SimpleDate.fromUTCDateString(membership.createdAt).toMediumString()}</Info>
            </React.Fragment>
          ))}
          <SignOutButton onClick={this.handleSignOutClicked}>Sign out</SignOutButton>
        </Content>
      </Modal>
    )
  }
}

export default withLoader<LoadedProps, PassedProps>(ProfileModal, <PageLoader />, async (context) => {
  const [attributes, memberships] = await Promise.all([getUserAttributes(context.user.id), getMemberships(context.user.id)])
  return { attributes, memberships }
})

const Attributes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  & > * {
    margin-right: 4px;
  }
`
const SignOutButton = styled(Button)`
  border-color: #ff3636;
  color: #ff3636;
  margin: 10px auto 5px auto;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 8px;
`
const Name = styled.span`
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  margin: 5px 0;
`
const Email = styled.span`
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
`
const Info = styled.span`
  display: inline-block;
  margin: 5px 0;
`