import styled from 'styled-components/macro'
import posed from 'react-pose'

const Button = posed.div({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
})

const StyledButton = styled(Button)`
  padding: 6px 9px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  background-color: #fff;
  border: 1px solid #999;
  min-width: 30px;
  width: fit-content;
  color: #000;
  outline: transparent;
  user-select: none;
`

export default StyledButton