import styled from 'styled-components/macro'

export enum Colors {
  MainBlue = '#b3e5fc',
  MediumBlue = '#78B0D0',
  CalendarBorderGray = '#dadce0',
  CalendarEventCoral = '#ffd1d1',
  AttributePurple = '#f2b1ff',
  EventBlue = '#50a6ff',
  ShiftTypePapaya = '#ffe9da',
  AdminGreen = '#88e2b0',
}

export enum BoxShadow {
  // Light = '0px 1px 3px 0px #999',
  Light = '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
}

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const FlexRow = styled.div`
  display: flex;
`