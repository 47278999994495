import { SerializedDate } from './SimpleDate'
import { PersonI } from './entity-interfaces'

export function makeArrayCycler<T> (arr: T[]) {
  let index = 0
  return () => {
    if (index === arr.length) {
      index = 0
    }
    return arr[index++]
  }
}

export enum HttpStatus {
  Ok = 200,
  Created = 201,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  ServerError = 500,
}

// UserProps could always be null, when a page loads from nextjs's cache for example
export interface UserProps {
  firstName: string,
  lastName: string,
  email: string,
  id: number
}

export interface PageProps {
  user: UserProps
  team: TeamProps
}

export interface TeamProps {
  name: string
  utcOffset: number
}